import { Field } from 'react-final-form'
import { FormRadio } from 'shards-react'

import FormGroup from '../FormGroup'

const RadioInput = ({ field }) => {
  return (
    <FormGroup field={field}>
      {field?.options?.map((option, index) => (
        <Field
          key={index}
          name={field?.name}
          type={field?.type}
          value={option}
          render={({ input }) => (
            <FormRadio
              {...field?.inputProps}
              key={option}
              name={input.name + option}
              checked={input?.checked}
              onChange={() => {
                input.onChange(option)
              }}>
              {option}
            </FormRadio>
          )}
        />
      ))}
      {field?.info}
    </FormGroup>
  )
}

export default RadioInput
