const PassandeProvning = () => {
  return (
    <div style={{ marginBottom: '30px' }}>
      <span>
        För att du som kund ska få handla i de finansiella instrument som SCF
        erbjuder måste vi först säkerställa att du har tillräcklig kunskap om de
        instrument du kan investera i. Ange nedan din utbildning, erfarenhet
        samt volym och frekvens för handel.
      </span>
    </div>
  )
}

export default PassandeProvning
