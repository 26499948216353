import React from 'react'

const Success = () => {
  // Your application has indicated there's an error
  window.setTimeout(function () {
    // Move to a new location or you can do something else
    window.location.href = 'https://www.stockholmcorp.se'
  }, 5000)

  return (
    <div style={{ margin: '300px auto' }}>
      <h2>SUCCESS</h2>
      You will be redirected to the StockholmCorp homepage shortly.
    </div>
  )
}

export default Success
