import { Field } from 'react-final-form'
import { FormGroup as ShardFormGroup } from 'shards-react'

import Wizard from '../../Wizard'

const Condition = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      if (Array.isArray(value)) return value.includes(is) ? children : null
      if (Array.isArray(is)) return is.includes(value) ? children : null
      return value === is ? children : null
    }}
  </Field>
)

const FormGroup = ({ children, field }) => {
  return (
    <ShardFormGroup className="w-100" {...field?.shardFormGroupProps}>
      {field?.header && <h4>{field?.header}</h4>}
      {field?.condition ? (
        <Condition when={field?.condition?.when} is={field?.condition?.is}>
          <label
            style={{
              color: '#32B4D2',
              fontWeight: '400',
              fontFamily: 'open sans',
              fontSize: '16px',
            }}
            htmlFor={field?.name}>
            {field?.label}
          </label>
          {children}
        </Condition>
      ) : (
        <div>
          <label
            style={{
              color: '#32B4D2',
              fontWeight: '400',
              fontFamily: 'open sans',
              fontSize: '16px',
            }}
            htmlFor={field?.name}>
            {field?.label}
          </label>
          {children}
        </div>
      )}

      <Wizard.Error name={field?.name} />

      {field?.footer && <span>{field?.footer}</span>}
    </ShardFormGroup>
  )
}

export default FormGroup
