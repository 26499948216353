import './progressbar.css'

import { Progress } from 'shards-react'

const ProgressBar = ({ value, nrOfPages }) => {
  const percentValue = (100 / nrOfPages) * (value + 1)

  return (
    <div>
      <span
        style={{
          fontFamily: 'open sans',
          fontSize: '12px',
          fontWeight: '700',
          color: '#002F62',
        }}>
        Steg
      </span>
      <span
        style={{
          float: 'right',
          fontFamily: 'open sans',
          fontSize: '12px',
          fontWeight: '700',
          color: '#002F62',
        }}>
        {value + 1}/{nrOfPages}
      </span>
      <Progress multi style={{ borderRadius: '0px' }}>
        <Progress bar value={percentValue}></Progress>
      </Progress>
    </div>
  )
}

export default ProgressBar
