import MiddleText from './descriptionTexts/middleText'
import ProfessionellKundJuridisk from './descriptionTexts/professionellKundJuridisk'
import SkatteStatusJuridiskPerson from './descriptionTexts/skatteStatusJuridiskPerson'
import { sharedFields } from './sharedFields'

export const juridiskPerson = [
  ...sharedFields,
  {
    name: 'Step Eight',
    title: 'Kundklassificering',
    description: <MiddleText></MiddleText>,
    fields: [
      {
        name: 'foretagetsNamn',
        type: 'text',
        label: <div style={{ color: '#32B4D2' }}>Företagets namn</div>,
      },
      {
        name: 'foretagetsOrgNummer',
        type: 'text',
        label: 'Företagets organisationsnummer',
      },
      {
        name: 'foretagetsAdress',
        type: 'text',
        label: 'Företagets adress - inklusive postnummer och ort',
      },
      {
        name: 'foretagetsLand',
        type: 'text',
        label: 'Land',
      },
      {
        name: 'namnPaRepresentant',
        type: 'text',
        label: 'Namn på företagets representant',
      },
      {
        name: 'personNummerRepresentant',
        type: 'text',
        label: 'Personnummer på representant',
      },
      {
        name: 'telefonRepresentant',
        type: 'text',
        label: 'Telefon (dagtid)',
      },
      {
        name: 'epostRepresentant',
        type: 'text',
        label: 'E-postadress',
      },
      {
        name: 'syfteMedInvestJuridisk',
        type: 'checkbox',
        options: ['Sparande/placering', 'Annat'],
        label: 'Vad är syftet med investeringen?',
      },
      {
        name: 'syfteMedInvestJuridiskAnnat',
        type: 'text',
        placeholder: 'Annat syfte',
        condition: { when: 'syfteMedInvestJuridisk', is: 'Annat' },
        label: 'Annat:',
      },
      {
        name: 'varifranKommerPengarnaJuridisk',
        type: 'checkbox',
        options: [
          'Sparande/placering',
          'Företagsförsäljning',
          'Intäkter från företaget',
          'Fastighetsförsäljning',
          'Annat',
        ],
        label: 'Varifrån kommer de pengar du investerar?',
      },
      {
        name: 'varifranKommerPengarnaJuridiskAnnat',
        type: 'text',
        placeholder: 'Annat syfte',
        condition: { when: 'varifranKommerPengarnaJuridisk', is: 'Annat' },
        label: 'Annat:',
      },
    ],
  },
  {
    name: 'Step Nine',
    title: 'Ägare av minst 25% av kapital eller röster',
    description:
      ' Var god tillkännage ägande av minst 25% av kapital eller röster i bolaget. Bortse från denna sektion om bolaget är börsnoterat inom ESS eller om bolaget står under Finansinspektionens tillsyn. Använd knappen Lägg till huvudägare för att lägga till ytterligare huvudägare (minst 25% av företargets kapital eller röster). En delägare räknas som en huvudägare vid minst ett 25 % aktieinnehav, därför kan du lägga till 1-4 huvudägare. ',
    fields: [
      {
        name: 'delagare',
        type: 'fieldarray',
        children: [
          {
            name: 'name',
            type: 'text',
            placeholder: 'Namn',
            label: 'Namn',
          },
          {
            name: 'personOrgNummer',
            type: 'text',
            placeholder: 'Personnummer-/organisationsnummer',
            label: 'Personnummer-/organisationsnummer',
          },
          {
            name: 'ownership',
            type: 'text',
            suffix: '%',
            placeholder: '25',
            label: 'Ägande (minst 25%)',
          },
          {
            name: 'pep',
            type: 'radio',
            options: ['Ja', 'Nej'],
            label:
              'PEP-deklaration för ägare av minst 25% av kapital eller röster*',
          },
          {
            name: 'pepAnhorig',
            type: 'radio',
            options: ['Ja', 'Nej'],
            label:
              'Är du en familjemedlem eller känd medarbetare till en PEP?**',
          },
        ],
        pepDescription1:
          '*Personer som har eller har haft viktiga offentliga funktioner. Viktiga offentliga funktioner inkluderar, till exempel, stats- eller regeringschefer, riksdagsledamöter, ministrar, domare vid Högsta domstolen och Högsta förvaltningsdomstolen, riksrevisorerna, Riksbankens direktion, ambassadörer, höga officerare, VD eller styrelseledamot i statsägda företag eller en person i ledningen för en internationell organisation (t.ex. FN, Europarådet, NATO eller WTO).',
        pepDescription2:
          '**Med familjemedlem avses maka/make, registrerad partner, sambo, barn och deras make/maka, registrerade partner eller sambor samt föräldrar. Med känd medarbetare avses en person som agerar som huvudman på en PEP:s vägnar eller en person som på annat sätt har en nära affärsrelation med en PEP eller en person som är verklig huvudman för en juridisk person/juridisk enhet, som har bildats till förmån för en PEP. Se ovan för definition av PEP.',
      },
    ],
  },
  {
    name: 'Step Nine',
    title: 'Stiftelser, truster eller liknande',
    description:
      'Om teckning görs för en kapitalförsäkring, stiftelse, trust eller liknande, var god specificera namn och adress på framtida förmånstagare, alternativt namnen på de fysiska personer som har huvudintresse i den juridiska personens bildande eller verksamhet. Görs teckningen för en kapitalförsäkring, stiftelse, trust eller liknande?',
    fields: [
      {
        name: 'stiftelseEllerTrust',
        type: 'radio',
        options: ['Ja', 'Nej'],
      },
      {
        name: 'stiftelseEllerTrustNamn',
        type: 'text',
        condition: { when: 'stiftelseEllerTrust', is: 'Ja' },
        label: 'Namn',
      },
      {
        name: 'stiftelseEllerTrustPersonOrgNummer',
        type: 'text',
        condition: { when: 'stiftelseEllerTrust', is: 'Ja' },
        label: 'Person-/organisationsnummer',
      },
      {
        name: 'stiftelseEllerTrustAdress',
        type: 'text',
        condition: { when: 'stiftelseEllerTrust', is: 'Ja' },
        label: 'Adress - inklusive postnummer och ort',
      },
      {
        name: 'stiftelseEllerTrustLand',
        type: 'text',
        condition: { when: 'stiftelseEllerTrust', is: 'Ja' },
        label: 'Land',
      },
    ],
  },
  {
    name: 'Step Ten',
    title:
      'Obligatoriska uppgifter gällande amerikansk skattestatus, FATCA och CRS',
    description: <SkatteStatusJuridiskPerson></SkatteStatusJuridiskPerson>,
    fields: [
      {
        name: 'skatteSkyldigJuridisk',
        type: 'radio',
        options: ['Ja', 'Nej'],
      },
      {
        name: 'skatteSkyldigJuridiskLand',
        type: 'text',
        condition: { when: 'skatteSkyldigJuridisk', is: 'Ja' },
        label: 'Uppge land:',
      },
      {
        name: 'skatteSkyldigJuridiskTin',
        type: 'text',
        condition: { when: 'skatteSkyldigJuridisk', is: 'Ja' },
        label:
          'Uppge TIN (Taxpayer Identification Number)/GIIN (Global Intermediary Identification Number):',
      },
    ],
  },
  {
    name: 'Step Eleven',
    title: 'Försäkring',
    description:
      'Jag/vi försäkrar att ovanstående uppgifter som jag/vi lämnat i detta frågeformulär är aktuella, exakta och korrekta. Jag/vi lovar att omedelbart informera Stockholm Corporate Finance AB om eventuella förändringar.',
    fields: [
      {
        name: 'ortJuridisk',
        type: 'text',
        label: 'Ort',
      },
      {
        name: 'namnJuridisk',
        type: 'text',
        label: 'Namn',
      },
      {
        name: 'datumJuridisk',
        type: 'date',
        label: 'Datum:',
      },
    ],
  },
  {
    name: 'Step Twelve',
    title: 'Begäran att få bli klassad som en professionell kund',
    description: <ProfessionellKundJuridisk></ProfessionellKundJuridisk>,
    fields: [
      {
        label: 'Vill bolaget begära att ses som en profesionell kund?',
        name: 'omklassificering',
        type: 'radio',
        options: ['Ja', 'Nej'],
      },
      {
        name: 'sekJuridisk',
        type: 'text',
        condition: { when: 'omklassificering', is: 'Ja' },
        label: 'SEK:',
      },
      {
        name: 'balansomslutning',
        type: 'text',
        condition: { when: 'omklassificering', is: 'Ja' },
        label: 'Balansomslutning, SEK:',
      },
      {
        name: 'egetKapital',
        type: 'text',
        condition: { when: 'omklassificering', is: 'Ja' },
        label: 'Eget kapital, SEK:',
      },
      {
        name: 'rorelseresultat',
        type: 'text',
        condition: { when: 'omklassificering', is: 'Ja' },
        label: 'Rörelseresultat vinst/förlust, SEK:',
      },
      {
        name: 'verksamhetsomrade',
        type: 'text',
        condition: { when: 'omklassificering', is: 'Ja' },
        label: 'Huvudsakligt verksamhetsområde:',
      },
      {
        name: 'behandlasSomProfessionellKundJuridisk',
        type: 'checkbox',
        options: ['Kriterium A', 'Kriterium B', 'Kriterium C'],
        label:
          'Härmed ansöker jag om att behandlas som en professionell kund. Följande av de tre ovanstående kriterierna är uppfyllda och skriftligt bevis på detta kommer att tillhandahållas separat.',
        condition: { when: 'omklassificering', is: 'Ja' },
      },
    ],
  },
]
