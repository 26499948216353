import {
  CheckboxInput,
  DateInput,
  FieldArray,
  FileInput,
  RadioInput,
  TextInput,
} from './inputs'

export const getFieldInputComponent = type => {
  switch (type) {
    case 'checkbox':
      return CheckboxInput
    case 'text':
      return TextInput
    case 'email':
      return TextInput
    case 'radio':
      return RadioInput
    case 'fieldarray':
      return FieldArray
    case 'file':
      return FileInput
    case 'date':
      return DateInput
    default:
      return () => <p>{type} - This type is not implemented yet.</p>
  }
}

export const checkRequiredValidation = (field, values) => {
  if (Object.prototype.hasOwnProperty.call(field, 'condition')) {
    const conditionValue = values?.[field?.condition?.when]

    if (Array.isArray(conditionValue) || Array.isArray(field?.condition?.is)) {
      if (
        (conditionValue?.includes(field?.condition?.is) &&
          !values?.[field?.name]) ||
        (field?.condition?.is?.includes(conditionValue) &&
          !values?.[field?.name])
      ) {
        return 'Obligatoriskt'
      } else {
        return null
      }
    } else if (
      conditionValue === field?.condition?.is &&
      !values?.[field?.name]
    ) {
      return 'Obligatoriskt'
    } else {
      return null
    }
  } else if (!values?.[field?.name]) {
    return 'Obligatoriskt'
  } else if (
    Array.isArray(values?.[field?.name]) &&
    values?.[field?.name]?.length < 1
  ) {
    return 'Obligatoriskt'
  }
}
