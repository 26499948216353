const ProfessionellKund = () => {
  return (
    <div>
      <p>
        Kundklassificering kan komma att ändras till följd av ny information
        från kunden. Alla privatpersoner som är kunder hos Stockholm Corporate
        Finance har möjlighet att ansöka om omklassificering från
        icke-professionell till professionell, förutsatt att{' '}
        <span style={{ fontWeight: 'bold' }}>
          minst två av tre nedanstående kriterier är uppfyllda
        </span>
        :
      </p>
      <p style={{ padding: '0 20px 0 20px' }}>
        <span style={{ fontWeight: 'bold' }}>A.</span> – Kunden har genomfört
        transaktioner, av betydande storlek, på relevant marknadsplats med en
        genomsnittlig frekvens av 10 gånger per kvartal under de fyra senaste
        kvartalen.
      </p>
      <p style={{ padding: '0 20px 0 20px' }}>
        <span style={{ fontWeight: 'bold' }}>B.</span> – Storleken på kundens
        värdepappersportfölj, definierad som att inkludera kontantinsättningar
        och finansiella instrument, överstiger 500 000 EUR.
      </p>
      <p style={{ padding: '0 20px 0 20px' }}>
        <span style={{ fontWeight: 'bold' }}>C.</span> – Kunden arbetar eller
        har arbetat inom den finansiella sektorn under minst ett års tid på en
        professionell nivå, vilket förutsätter kunskap om de transaktioner eller
        tjänster som är relevanta.
      </p>
    </div>
  )
}

export default ProfessionellKund
