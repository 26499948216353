import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { useField } from 'react-final-form'

import FormGroup from '../FormGroup'

const FileInput = ({ field }) => {
  const { input } = useField(field?.name)
  const onDrop = useCallback(async acceptedFiles => {
    input.onChange(acceptedFiles[0])
  }, [])
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: 'application/pdf',
  })

  const files = acceptedFiles.map(file => <li key={file.path}>{file.path}</li>)

  return (
    <FormGroup field={field}>
      <section className="container">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>
            Dra och släpp din fil här eller klicka för att ladda upp. (.pdf)
          </p>
        </div>
        <aside>
          <h6>Bifogad fil:</h6>
          <ul>{files}</ul>
        </aside>
      </section>
    </FormGroup>
  )
}

export default FileInput
