const ProfessionellKundJuridisk = () => {
  return (
    <div>
      <p>
        Bolaget kan begäran att få bli behandlad som en professionell kund genom
        att skriftligen begära detta hos SCF och att SCF godkänner begäran
        <br></br>
        <span style={{ fontWeight: 'bold' }}>
          För att kunna godkänna begäran måste två av tre nedanstående kriterier
          vara uppfyllda.
        </span>
      </p>
      <p style={{ padding: '0 20px 0 20px' }}>
        <span style={{ fontWeight: 'bold' }}>A.</span> – balansomslutning minst
        20 000 000 EUR
      </p>
      <p style={{ padding: '0 20px 0 20px' }}>
        <span style={{ fontWeight: 'bold' }}>B.</span> – nettoomsättning minst
        40 000 000 EUR och
      </p>
      <p style={{ padding: '0 20px 0 20px' }}>
        <span style={{ fontWeight: 'bold' }}>C.</span> – eget kapital minst 2
        000 000 EUR.
      </p>
    </div>
  )
}

export default ProfessionellKundJuridisk
