import { Field } from 'react-final-form'
import {
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'shards-react'

import FormGroup from '../FormGroup'

const TextInput = ({ field }) => {
  return (
    <FormGroup field={field}>
      <Field
        name={field?.name}
        type={field?.type}
        suffix={field?.suffix}
        placeholder={field?.placeholder}
        options={field?.options}
        render={({ input, placeholder, meta: { valid, invalid, touched } }) => (
          <InputGroup>
            <FormInput
              style={{ border: '1px solid #002F62', borderRadius: '0' }}
              {...field?.inputProps}
              {...input}
              placeholder={placeholder}
              {...(touched ? { valid, invalid } : {})}
            />
            {field?.suffix ? (
              <InputGroupAddon type="append">
                <InputGroupText>{field?.suffix}</InputGroupText>
              </InputGroupAddon>
            ) : null}
          </InputGroup>
        )}
      />
    </FormGroup>
  )
}

export default TextInput
