import { useEffect, useState } from 'react'
import { useField } from 'react-final-form'
import { FormCheckbox } from 'shards-react'

import FormGroup from '../FormGroup'

const CheckboxInput = ({ field }) => {
  const { input } = useField(field?.name)

  const [values, setValues] = useState(
    field?.options?.reduce((acc, option) => {
      acc[option] = input?.value?.includes(option)
      return acc
    }, {}),
  )

  const handleChange = (e, option) => {
    const newState = {}
    newState[option] = !values[option]
    setValues({ ...values, ...newState })
  }

  useEffect(() => {
    input.onChange(
      Object.keys(values)?.reduce((acc, key) => {
        if (values[key]) {
          acc.push(key)
        }
        return acc
      }, []),
    )
  }, [values])

  return (
    <FormGroup field={field}>
      {field?.options?.map((option, index) => (
        <FormCheckbox
          key={index}
          checked={values[option]}
          onChange={e => handleChange(e, option)}>
          {option}
        </FormCheckbox>
      ))}
    </FormGroup>
  )
}

export default CheckboxInput
