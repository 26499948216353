const KundDokumentation = () => {
  return (
    <div>
      <p>
        Nedanstående frågor är relaterade till din personliga erfarenhet som
        investerare. Dina svar hjälper oss att tillhandahålla lämpliga
        finansiella instrument som passar din erfarenhet och kunskap. En
        juridisk person bedöms av bolagets företrädare. Din erfarenhet kan falla
        inom ramen för en av tre kategorier, förklarade nedan.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Nybörjarinvesterare</span> – har
        begränsad eller ingen kännedom. En nybörjarinvesterare har en begränsad
        förståelse av kapitalmarknaderna eller erfarenhet av aktiers, fonders
        eller obligationers karaktär och risker eller är en
        förstagångsinvesterare.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Erfaren investerare</span> –
        förstår större delen av investeringsprinciperna. En erfaren investerare
        har god förståelse av kapitalmarknaderna och kan fatta informerade
        investeringsbeslut med hjälp av prospekt, investeringserbjudanden samt
        förstå särskilda risker.
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>Avancerad investerare</span> – är
        en professionell investerare och/eller en frekvent investerare. En
        avancerad investerare har en mycket god kännedom om kapitalmarknaderna
        och expertkunskap om finansiella instruments egenskaper.
      </p>
      <p>
        Ange din kännedom om och erfarenhet av instrumenten nedan genom att
        sätta kryss i lämplig ruta
      </p>
    </div>
  )
}

export default KundDokumentation
