import { Link } from 'react-router-dom'

const Error = () => {
  return (
    <div style={{ margin: '300px auto' }}>
      <h2>Error 404</h2>
      The requested page does not exist.
      <br />
      <Link style={{ textDecoration: 'none' }} to="/">
        Click here to go back.
      </Link>
    </div>
  )
}

export default Error
