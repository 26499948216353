const copyTemplate = async templateId => {
  const response = await fetch(`/api/scrive/new/${templateId}`, {
    method: 'POST',
  })
    .then(res => res.json())
    .catch(err => err)

  return response
}

export default copyTemplate
