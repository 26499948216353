import arrayMutators from 'final-form-arrays'
import { createPersistDecorator } from 'final-form-persist'
import React, { useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import ScaleLoader from 'react-spinners/ScaleLoader'
import {
  Button,
  Col,
  Container,
  Form as ShardForm,
  FormFeedback,
  Row,
} from 'shards-react'

import { checkRequiredValidation } from '../methods'
import ProgressBar from './ProgressBar'

const Wizard = ({ onSubmit, children }) => {
  const [page, setPage] = useState(0)
  const [values, setValues] = useState({})
  const [disabled, setDisabled] = useState(false)
  const [submitButton, setSubmitButton] = useState('Skicka')
  const isLastPage = page === React.Children.count(children) - 1
  const activePage = React.Children.toArray(children)[page]
  const nrOfPages = React.Children.toArray(children).length

  const next = values => {
    setPage(Math.min(page + 1, children?.length - 1))
    setValues(values)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const prev = () => {
    setPage(Math.max(page - 1, 0))
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const validate = values => {
    const requiredValidation = activePage?.props?.step?.fields?.reduce(
      (acc, field) => {
        if (field?.type === 'fieldarray') {
          const fieldArrayValue = values?.[field?.name]

          if (Array.isArray(fieldArrayValue)) {
            const fieldArrayErrors = fieldArrayValue?.map(item => {
              const fieldArrayItemErrors = field?.children?.reduce(
                (childAcc, child) => {
                  const error = checkRequiredValidation(child, item)
                  if (error) {
                    childAcc[child?.name] = error
                  }

                  return childAcc
                },
                {},
              )
              return fieldArrayItemErrors
            })

            acc[field?.name] = fieldArrayErrors
          }
        } else {
          const error = checkRequiredValidation(field, values)
          if (error) {
            acc[field?.name] = error
          }
        }
        return acc
      },
      {},
    )

    const extraValidation = activePage?.props?.validate
      ? activePage?.props?.validate(values)
      : {}

    return {
      ...requiredValidation,
      ...extraValidation,
    }
  }

  const { persistDecorator, clear } = useMemo(
    () =>
      createPersistDecorator({
        name: 'SCF-KYC',
        debounceTime: 500,
        blacklist: [
          'bilaga',
          // 'personnummer',
          // 'telefon',
          // 'epost',
          // 'personNummerRepresentant',
          // 'telefonRepresentant',
          // 'epostRepresentant',
        ],
      }),
    [],
  )

  const handleSubmit = values => {
    if (isLastPage) {
      setDisabled(true)
      onSubmit(values)
      clear()

      setSubmitButton(
        <div style={{ marginTop: '-9px' }}>
          <ScaleLoader color={'#32b4d2'} height={'30px'} />
        </div>,
      )
    } else {
      next(values)
    }
  }

  return (
    <Form
      decorators={[persistDecorator]}
      initialValues={values}
      validate={validate}
      onSubmit={handleSubmit}
      mutators={{
        // potentially other mutators could be merged here
        ...arrayMutators,
      }}>
      {({ handleSubmit }) => (
        <ShardForm onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col style={{ minHeight: 'calc(100vh - 130px - 3rem)' }}>
                <div className="d-inline-flex flex-wrap">{activePage}</div>
              </Col>
            </Row>
            <Row
              className="position-sticky py-3"
              style={{
                backgroundColor: '#f9f9f9',
                height: '130px',
                bottom: '0',
                zIndex: 99,
              }}>
              <Col className="col-12 mb-2">
                <ProgressBar
                  value={page}
                  animated={true}
                  nrOfPages={nrOfPages}
                />
              </Col>
              {page > 0 && (
                <Col>
                  <Button
                    style={{
                      minWidth: '130px',
                      height: '40px',
                      backgroundColor: 'white',
                      color: '#002F62',
                      border: '1 px solid #002F62',
                      borderRadius: '0px',
                    }}
                    type="button"
                    onClick={prev}
                    outline
                    theme="secondary">
                    Föregående
                  </Button>
                </Col>
              )}
              {!isLastPage && (
                <Col className="d-flex justify-content-end">
                  <Button
                    style={{
                      minWidth: '130px',
                      height: '40px',
                      backgroundColor: '#002F62',
                      color: 'white',
                      border: 'none',
                      borderRadius: '0px',
                    }}
                    type="submit"
                    outline>
                    Nästa
                  </Button>
                </Col>
              )}
              {isLastPage && (
                <Col className="d-flex justify-content-end">
                  <Button
                    style={{
                      minWidth: '130px',
                      height: '40px',
                      backgroundColor: '#002F62',
                      color: 'white',
                      border: 'none',
                      borderRadius: '0px',
                    }}
                    type="submit"
                    disabled={disabled}
                    className="submit">
                    <div>{submitButton}</div>
                  </Button>
                </Col>
              )}
            </Row>
          </Container>
        </ShardForm>
      )}
    </Form>
  )
}

Wizard.Page = ({ children }) => children

Wizard.Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? (
        <FormFeedback style={{ display: 'block' }}>{error}</FormFeedback>
      ) : null
    }
  />
)

export default Wizard
