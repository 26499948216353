const MiddleText = () => {
  return (
    <div style={{ marginBottom: '30px' }}>
      <span className="my-2">
        Stockholm Corporate Finance AB klassificerar sina kunder som
        icke-professionella såvida annat inte tillkännagivits.
        Kundklassificering kan komma att ändras till följd av ny information
        från kunden. Alla som är kunder hos Stockholm Corporate Finance har
        möjlighet att ansöka om omklassificering från icke-professionell till
        professionell. Se sista avsnittet i detta dokument.
      </span>
    </div>
  )
}

export default MiddleText
