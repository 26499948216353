const updateDocument = async (documentId, document) => {
  const newDocument = await fetch(`/api/scrive/updatedocument/${documentId}`, {
    method: 'POST',
    body: Object.keys(document)
      .map(key => key + '=' + document[key])
      .join('&'),
  })
    .then(res => res.json())
    .catch(err => err)
  return newDocument
}

export default updateDocument
