import { FieldArray as FinalFieldArray } from 'react-final-form-arrays'
import { Button } from 'shards-react'

import { getFieldInputComponent } from '../../methods'

const FieldArray = ({ field }) => (
  <div>
    {field.header}
    <br></br>
    <br></br>
    {field.pepDescription1}
    <br></br>
    <br></br>
    {field.pepDescription2}
    <br></br>
    <br></br>
    <FinalFieldArray name="delagare">
      {({ fields }) => (
        <div>
          {fields.map((owner, i) => (
            <div key={owner}>
              <h6>Huvudägare {i + 1}</h6>
              {field.children.map((child, i) => {
                const Component = getFieldInputComponent(child?.type)
                return (
                  <div key={i}>
                    <Component
                      field={{ ...child, name: `${owner}.${child.name}` }}
                    />
                  </div>
                )
              })}
              <Button
                className="btn btn-danger"
                onClick={() => fields.remove(i)}
                style={{ minWidth: '165px', margin: '15px 0px' }}>
                Ta bort huvudägare
              </Button>
              <hr></hr>
            </div>
          ))}
          <div className="buttons" style={{ marginTop: '20px' }}>
            <Button
              type="button"
              disabled={fields.length >= 4}
              style={{ minWidth: '165px' }}
              onClick={() => fields.push('delagare', undefined)}>
              Lägg till huvudägare
            </Button>
          </div>
        </div>
      )}
    </FinalFieldArray>
  </div>
)

export default FieldArray
