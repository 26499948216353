import Forsakring from './descriptionTexts/forsakring'
import KundDokumentation from './descriptionTexts/kundDokumentation'
import MiddleText from './descriptionTexts/middleText'
import PassandeProvning from './descriptionTexts/passandeProvning'
import ProfessionellKund from './descriptionTexts/professionellKund'
import SkatteStatus from './descriptionTexts/skatteStatus'

export const sharedFields = [
  {
    name: 'Step One',
    title: 'Kundklassificering',
    description: <MiddleText></MiddleText>,
    validate: values => {
      const errors = {}
      if (
        !values?.personnummer?.match(
          /^(\d{12}|\d{10}|\d{6}-\d{4}|\d{8}-\d{4}|\d{8} \d{4}|\d{6} \d{4})/g,
        )
      ) {
        errors.personnummer = 'Ogiltigt personnummer'
      }
      if (!values?.epost?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)) {
        errors.epost = 'Ogiltig epost-adress'
      }
      return errors
    },
    fields: [
      {
        name: 'fornamn',
        type: 'text',
        placeholder: 'Förnamn',
        label: 'Förnamn',
        // condition ???
      },
      {
        name: 'efternamn',
        type: 'text',
        placeholder: 'Efternamn',
        label: 'Efternamn',
        // condition ???
      },
      {
        name: 'personnummer',
        type: 'text',
        placeholder: 'ÅÅÅÅMMDDXXXX',
        label: 'Personnummer (ååååmmddxxxx)',
        // condition ???
      },
      {
        name: 'adress',
        type: 'text',
        placeholder: 'Hemvägen 1',
        label: 'Adress',
        // condition ???
      },
      {
        name: 'postnummer',
        type: 'text',
        placeholder: '123 45',
        label: 'Postnummer',
        shardFormGroupProps: {
          className: 'w-50 pr-2',
        },
        // condition ???
      },
      {
        name: 'ort',
        type: 'text',
        placeholder: 'Stockholm',
        label: 'Ort',
        shardFormGroupProps: {
          className: 'w-50 pl-2',
        },
        // condition ???
      },
      {
        name: 'land',
        type: 'text',
        placeholder: 'Land',
        label: 'Land',
        // condition ???
      },
      {
        name: 'medborgarskap',
        type: 'text',
        placeholder: 'Medborgarskap',
        label: 'Medborgarskap',
        // condition ???
      },
      {
        name: 'telefon',
        type: 'text',
        placeholder: 'Telefonnr',
        label: 'Telefon (dagtid)',
        // condition ???
      },
      {
        name: 'epost',
        type: 'text',
        placeholder: 'E-postadress',
        label: 'E-postadress',
        // condition ???
      },
    ],
  },
  {
    name: 'Step Two',
    title: 'Passandeprövning',
    description: <PassandeProvning></PassandeProvning>,
    fields: [
      {
        name: 'finansiellUtbildning',
        type: 'radio',
        options: ['Ingen', 'Någon', 'Omfattande'],
        label: 'Finansiell utbildning',
        // condition ???
      },
      {
        name: 'professionellErfarenhetAvFinansbranschen',
        type: 'radio',
        options: [
          'Ingen',
          'Någon (mindre än 1 år)',
          'God kännedom (1-5 år)',
          'Omfattande (mer än 5 år)',
        ],
        label: 'Professionell erfarenhet av finansbranschen',
        // condition ???
      },
      {
        name: 'volymForHandel',
        type: 'radio',
        options: [
          'Ingen',
          '< 5000 €',
          '5000 - 10 000 €',
          '10 000 - 50 000 €',
          '> 50 000 €',
        ],
        label: 'Volym för handel',
        // condition ???
      },
      {
        name: 'frekvensOchTidsperiodFörHandelMedFinansiellaInstrument',
        type: 'radio',
        options: [
          'Ingen',
          'Viss handel - men ej i närtid (ej senaste 8 åren)',
          'Frekvent handel - men ej i närtid (senaste 8 åren)',
          'Viss handel i närtid (Senaste 3 åren)',
          'Frekvent handel (Senaste 3 åren)',
        ],
        label: 'Frekvens och tidsperiod för handel med finansiella instrument',
        // condition ???
      },
    ],
  },
  {
    name: 'Step Three',
    title: 'Erfarenhet av investeringar',
    description: <KundDokumentation></KundDokumentation>,
    fields: [
      {
        name: 'aktierUpptagnaTillHandelPaEnRegleradMarknadEllerMTF',
        type: 'radio',
        options: ['Nybörjare*', 'Erfaren', 'Avancerad'],
        label: 'Aktier upptagna till handel på en reglerad marknad eller MTF',
        header: 'Okomplicerade finansiella instrument',
        // condition ???
      },
      {
        name:
          'teckningsoptionerOchDepabesvisupptagnaTillHandelPaEnRegleradMarknadEllerMTF',
        type: 'radio',
        options: ['Nybörjare*', 'Erfaren', 'Avancerad'],
        label:
          'Teckningsoptioner och depåbevis upptagna till handel på en reglerad marknad eller MTF',
        // condition ???
      },
      {
        name: 'penningmarknadsintrument',
        type: 'radio',
        options: ['Nybörjare*', 'Erfaren', 'Avancerad'],
        label:
          'Penningmarknadsinstrument – räntebärande skuldebrev med kort löptid',
        // condition ???
      },
      {
        name: 'aktier',
        type: 'radio',
        options: ['Nybörjare*', 'Erfaren', 'Avancerad'],
        label:
          'Aktier – onoterade svenska aktier, onoterade utländska aktier samt TECKNINGSOPTIONER på dessa',
        header: 'Komplicerade finansiella instrument',
        // condition ???
      },
      {
        name: 'emissionsgarantier',
        type: 'radio',
        options: ['Nybörjare*', 'Erfaren', 'Avancerad'],
        label:
          'Emissionsgarantier – ett garantiavtal vid emissioner på en reglerad marknad eller MTF',
        // condition ???
      },
      {
        name: 'derivatPaEnRegleradMarknad',
        type: 'radio',
        options: ['Nybörjare*', 'Erfaren', 'Avancerad'],
        label:
          'Derivat på en reglerad marknad – innehavd köp-eller säljoption, terminskontrakt, future, forward ellerteckningsoption som omsätts på en reglerad marknad',
        // condition ???
      },
      {
        name: 'otcDerivat',
        type: 'radio',
        options: ['Nybörjare*', 'Erfaren', 'Avancerad'],
        label:
          'OTC-derivat – derivatinstrument som inte omsätts på en reglerad marknad',
        footer:
          '* = Komplicerade finansiella instrument är i allmänhet inte lämpade för nybörjarinvesterare',
        // condition ???
      },
    ],
  },
  {
    name: 'Step Four',
    title: 'Kapital och syfte med sparande',
    fields: [
      {
        name: 'vemsRakningHandlarDu',
        type: 'radio',
        options: [
          'För min egen räkning',
          'För en annan person genom fullmakt',
          'För en juridisk enhet',
          'Som förmyndare (bifoga förmyndarbevis)',
        ],
        label: '1. För vems räkning handlar du?',
        // condition ???
      },
      {
        name: 'bilaga',
        type: 'file',
        condition: {
          when: 'vemsRakningHandlarDu',
          is: [
            'Som förmyndare (bifoga förmyndarbevis)',
            'För en annan person genom fullmakt',
          ],
        },
        label: 'Bifoga dokument',
      },
      {
        name: 'syfteMedInvesteringen',
        type: 'checkbox',
        options: ['Sparande/placering', 'Annat'],
        label: '2. Vad är syftet med investeringen?',
        // condition ???
      },
      {
        name: 'syfteMedInvesteringenAnnat',
        type: 'text',
        placeholder: 'Annat syfte',
        condition: { when: 'syfteMedInvesteringen', is: 'Annat' },
        label: 'Annat syfte',
      },
      {
        name: 'varifranKommerPengarna',
        type: 'checkbox',
        options: [
          'Sparande/placering',
          'Företagsförsäljning',
          'Lön',
          'Arv/gåva',
          'Fastighetsförsäljning',
          'Annat',
        ],
        label: '3. Varifrån kommer de pengar du investerar?',
      },
      {
        name: 'varifranKommerPengarnaAnnat',
        type: 'text',
        placeholder: 'Annan källa för pengarna',
        condition: { when: 'varifranKommerPengarna', is: 'Annat' },
        label: 'Annan källa för pengarna',
      },
      {
        name: 'arDuPoliticalExposedPerson',
        type: 'radio',
        options: ['Ja', 'Nej'],
        label:
          '4. Är du en person i politisk utsatt utställning (Political Exposed Person, PEP)',
        info:
          'Personer som har eller har haft viktiga offentliga funktioner. Viktiga funktioner inkluderar, till exempel, stats- eller regeringschefer, riksdagsledamöter, ministrar, domare vid Högsta domstolen och Högsta förvaltningsdomstolen, riksrevisorerna, Riksbankens direktion, ambassadörer, höga officerare, VD eller styrelseledamot i statsägda företag eller person i ledningen för en internationell organisation (t.ex FN, Europarådet, NATO eller WTO).',
      },
      {
        name: 'familjemedlemPoliticalExposedPerson',
        type: 'radio',
        options: ['Ja', 'Nej'],
        label: 'Är du en familjemedlem eller känd medarbetare till en PEP?',
        info:
          'Med familjemedlem avses maka/make, registrerad partner, sambo, barn och deras make/maka, registrerade partner eller sambor samt föräldrar. Med känd medarbetare avses en person som agerar som huvudman på en PEP:s vägnar eller en person som på annat sätt har en nära affärsrelation med en PEP eller en person som är verklig huvudman för en juridisk person/juridisk enhet, som har bildats till förmån för en PEP. Se ovan för definition av PEP.',
      },
    ],
  },
  {
    name: 'Step Five',
    title:
      'Obligatoriska uppgifter gällande amerikansk skattestatus, FATCA och CRS',
    description: <SkatteStatus></SkatteStatus>,
    fields: [
      {
        name: 'skattskyldigIettAnnatLand',
        type: 'radio',
        options: ['Ja', 'Nej'],
      },
      {
        name: 'skattSkyldigIland',
        type: 'text',
        placeholder: 'Land',
        condition: { when: 'skattskyldigIettAnnatLand', is: 'Ja' },
        label: 'I vilket land?',
      },
      {
        name: 'taxpayerIdentificationNumber',
        type: 'text',
        placeholder: '',
        condition: { when: 'skattskyldigIettAnnatLand', is: 'Ja' },
        label: 'TIN (Taxpayer Identification Number)',
      },
    ],
  },
  {
    name: 'Step Six',
    title: 'Försäkring',
    description: <Forsakring></Forsakring>,
    fields: [
      {
        name: 'intygningsOrt',
        type: 'text',
        placeholder: 'Stockholm',
        label: 'Ort',
      },
      {
        name: 'intygningsNamn',
        type: 'text',
        placeholder: 'Förnamn, efternamn',
        label: 'Fullständigt namn',
      },
      {
        name: 'intygningsDatum',
        type: 'date',
        label: 'Datum:',
      },
    ],
  },
  {
    name: 'Step Seven',
    title: 'Begäran att få bli klassad som en professionell kund',
    description: <ProfessionellKund></ProfessionellKund>,
    fields: [
      {
        name: 'behandlasSomProfessionellKund',
        type: 'radio',
        options: ['Ja', 'Nej'],
        label: 'Vill du bli behandlad som en professionell kund?',
      },
      {
        name: 'professionellKundKriterier',
        type: 'checkbox',
        options: ['Kriterium A', 'Kriterium B', 'Kriterium C'],
        label:
          'Härmed ansöker jag om att behandlas som en professionell kund. Följande av de tre ovanstående kriterierna är uppfyllda och skriftligt bevis på detta kommer att tillhandahållas separat.',
        condition: { when: 'behandlasSomProfessionellKund', is: 'Ja' },
      },
    ],
  },
]
