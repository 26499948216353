import React from 'react'

const SkatteStatus = () => {
  return (
    <div>
      <span>
        På grund av den amerikanska skattelagen FATCA (Foreign Account Tax
        Compliance Act) och andra mellanstatliga överenskommelser mellan Sverige
        och amerikanska finansiella institut samt EU:s förordning om
        mellanstatligt utbyte av finansiell information, är Stockholm Corporate
        Finance AB, skyldigt att informera:−det amerikanska skatteverket om
        fondtillgångar som innehas av privatpersoner och företag som är
        skattskyldiga i USA och−det svenska skatteverket om fondtillgångar som
        innehas av privatpersoner och företag som är skattskyldiga i andra
        länder än Sverige.
      </span>
      <br></br>
      <br></br>
      <b>
        Kan du som JURIDISK PERSON anses vara helt (eller delvis) skattskyldig i
        ett annat land än Sverige?
      </b>
    </div>
  )
}

export default SkatteStatus
