import { sharedFields } from './sharedFields'

export const fysiskPerson = [
  ...sharedFields,
  // {
  //   name: 'Step Eight',
  //   fields: [
  //     {
  //       name: 'arDuPoliticalExposedPerson',
  //       type: 'radio',
  //       options: ['Ja', 'Nej'],
  //       label:
  //         '4. Är du en person i politisk utsatt utställning (Political Exposed Person, PEP)',
  //       info:
  //         'Personer som har eller har haft viktiga offentliga funktioner. Viktiga funktioner inkluderar, till exempel, stats- eller regeringschefer, riksdagsledamöter, ministrar, domare vid Högsta domstolen och Högsta förvaltningsdomstolen, riksrevisorerna, Riksbankens direktion, ambassadörer, höga officerare, VD eller styrelseledamot i statsägda företag eller person i ledningen för en internationell organisation (t.ex FN, Europarådet, NATO eller WTO).',
  //     },
  //   ],
  // },
]
