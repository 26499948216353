const setAuthorAttachments = async (values, documentId) => {
  const filesToAttach = ['bilaga']

  // Check if any of the files in filesToAttach exists, else return
  if (
    !filesToAttach.reduce((prev, fileName) => {
      return prev && values?.[fileName]
    }, true)
  ) {
    return
  }

  const formData = new FormData()
  formData.append(
    'attachments',
    JSON.stringify(
      filesToAttach.map(fileName => {
        const file = values?.[fileName]
        if (file) {
          return {
            name: file?.name,
            required: false,
            add_to_sealed_file: true,
            file_param: fileName,
          }
        }
      }),
    ),
  )

  filesToAttach.forEach(fileName => {
    const file = values?.[fileName]
    if (file) {
      formData.append(fileName, file)
    }
  })

  return await fetch(`/api/scrive/setAuthorAttachments/${documentId}`, {
    method: 'POST',
    body: formData,
  })
}

export default setAuthorAttachments
