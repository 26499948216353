import { useEffect, useState } from 'react'
import { Field } from 'react-final-form'

import FormGroup from '../FormGroup'

const DateInput = ({ field }) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    let newDate = new Date()
    let date = newDate.getDate()
    let month = newDate.getMonth() + 1
    let year = newDate.getFullYear()

    const _value = `${year}-${month < 10 ? `0${month}` : month}-${
      date < 10 ? `0${date}` : date
    }`

    setValue(_value)
  }, [])

  if (!value) return null

  return (
    <FormGroup field={field}>
      <Field
        name={field?.name}
        value={value}
        defaultValue={value}
        initialValue={value}
        render={({ input }) => {
          return <span style={{ marginLeft: '5px' }}>{input?.value}</span>
        }}
      />
    </FormGroup>
  )
}

export default DateInput
