import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Error from './components/Error/Error'
import Footer from './components/footer/footer'
import Form from './components/Form'
import { fysiskPerson } from './components/Form/formTemplates/fysiskperson'
import { juridiskPerson } from './components/Form/formTemplates/juridiskperson'
import ScrollToTop from './components/Form/Wizard/scrollToTop'
import SelectTypeOfCustomer from './components/SelectTypeOfCustomer/selectTypeOfCustomer'
import Success from './components/Success/Success'

const App = () => {
  return (
    <div style={{ backgroundColor: '#f9f9f9' }}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/fysiskperson">
              <div className="d-flex align-items-center">
                <Form
                  form={fysiskPerson}
                  header="Fysisk person"
                  templateId={process.env.REACT_APP_FYS_TEMPLATE}
                />
              </div>
            </Route>
            <Route exact path="/juridiskperson">
              <div className="d-flex align-items-center">
                <Form
                  form={juridiskPerson}
                  header="Juridisk person"
                  templateId={process.env.REACT_APP_JUR_TEMPLATE}
                />
              </div>
            </Route>
            <Route exact path="/success">
              <div className="d-flex align-items-center">
                <Success />
              </div>
            </Route>
            <Route exact path="/">
              <div className="d-flex align-items-center">
                <SelectTypeOfCustomer />
              </div>
            </Route>
            <Route>
              <div className="d-flex align-items-center">
                <Error />
              </div>
            </Route>
          </Switch>
          <Footer></Footer>
        </ScrollToTop>
      </Router>
    </div>
  )
}

export default App
