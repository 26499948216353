export const formatDocument = async (values, document, form) => {
  const party = document.parties?.find(party => !party.is_author)
  const author = document.parties?.find(party => party.is_author)

  const allFields = form?.reduce((acc, step) => [...acc, ...step?.fields], [])

  const partyFields = party?.fields
    ?.map(item => {
      let value = Object.keys(values)
      let keyOfFields = item?.name
      if (item?.name?.includes('delagare')) {
        let nameSplit = item?.name.split(',')
        let delagareValues = Object?.keys?.(
          values?.[nameSplit[0]]?.[nameSplit[2]] || {},
        )
        if (item.type === 'radiogroup') {
          if (delagareValues?.includes(nameSplit[1])) {
            item.selected_value =
              values[nameSplit[0]][nameSplit[2]][nameSplit[1]]
          }
        } else {
          if (delagareValues.includes(nameSplit[1])) {
            let suffix = nameSplit[1] === 'ownership' ? ' %' : ''
            item.value =
              values[nameSplit[0]][nameSplit[2]][nameSplit[1]] + suffix
          }
        }
      }
      if (item.type === 'checkbox') {
        let split = item.name.split(',')
        keyOfFields = split[0]
        if (value.includes(keyOfFields)) {
          item.is_checked = values[keyOfFields].includes(split[1])
        }
      } else if (item.type === 'radiogroup') {
        if (value.includes(keyOfFields)) {
          item.selected_value = values[keyOfFields]
        }
      } else if (item.type === 'email') {
        item.value = values.epost
      } else if (item.type === 'personal_number') {
        item.value = values.personnummer
      } else if (item.type === 'mobile') {
        item.value = values.telefon
      } else if (item.type === 'name') {
        item.value = item.order === 1 ? values.fornamn : values.efternamn
      } else {
        if (value.includes(keyOfFields)) {
          item.value = values[keyOfFields]
        }
      }
      return item
    })
    .filter(item => {
      let checkedCondition = true
      const field = allFields?.find(field => field?.name === item?.name)
      if (field?.condition) {
        const { is, when } = field?.condition
        const conditionValue = values?.[when]

        if (Array.isArray(conditionValue))
          checkedCondition = conditionValue?.includes(is)

        if (Array.isArray(is)) checkedCondition = is?.includes(conditionValue)

        checkedCondition = conditionValue === is
      }

      if (item.type === 'checkbox') {
        return item.is_checked && checkedCondition
      }

      if (item.type === 'radiogroup') {
        return item.selected_value && checkedCondition ? true : false
      }

      if (item.type === 'text') {
        return item.value && checkedCondition ? true : false
      }

      return true
    })

  party.delivery_method = 'api'
  party.sign_success_redirect_url = process.env.REACT_APP_SUCCESS_PAGE
  party.reject_redirect_url = process.env.REACT_APP_ERROR_PAGE
  party.authentication_method_to_sign = 'se_bankid'

  return {
    document: JSON.stringify({
      ...document,
      title: 'SCF KYC ' + values.intygningsNamn,
      parties: [author, { ...party, fields: partyFields }],
      api_callback_url: process.env.REACT_APP_API_CALLBACK,
    }),
  }
}
