import './footer.css'

import { Col, Container, Row } from 'shards-react'

import BankId from './bankID.svg'
import ScfLogo from './scf-logo.png'

const Footer = () => {
  return (
    <Container
      className="d-flex flex-column footer-container"
      style={{
        minWidth: '100%',
      }}>
      <Row>
        <Col sm="12" md="4" lg="4" className="footer-col">
          <img id="bankid" className="center-content" src={`${BankId}`}></img>
        </Col>
        <Col sm="12" md="4" lg="4" className="footer-col">
          <div className="center-content footer-header">
            <h5 style={{ color: '#fff' }}>
              Trygg och säker verifiering via BankID
            </h5>
            <span className="footer-span">
              Genom vår digitala KYC slipper du som kund skriva ut onödigt
              papper eller bifoga legitimationer via post. Allt signeras säkert,
              tryggt och korrekt via BankID.
            </span>
          </div>
        </Col>
        <Col sm="12" md="4" lg="4" className="footer-col">
          <img
            className="center-content"
            id="scf-logo"
            src={`${ScfLogo}`}></img>
        </Col>
        {/* <Col md="12" lg="3" className="footer-col">
          <div className="center-content footer-header">
            <h5 style={{ color: '#fff' }}>
              Trygg och säker verifiering via BankID
            </h5>
            <span className="footer-span">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit, sed
              commodo porttitor egestas in arcu nisl vitae. Diam netus <br />{' '}
              volutpat non nunc placerat.
            </span>
          </div>
        </Col> */}
      </Row>
    </Container>
  )
}

export default Footer
