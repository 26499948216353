const Forsakring = () => {
  return (
    <div style={{ marginBottom: '20px' }}>
      <span>
        Jag/vi försäkrar att jag/vi har läst och förstått ovanstående
        information och att de uppgifter som jag/vi lämnat i detta frågeformulär
        är aktuella, exakta och korrekta.<br></br>
        <br></br>Jag/vi lovar att omedelbart informera Stockholm Corporate
        Finance AB om eventuella förändringar.
      </span>
    </div>
  )
}

export default Forsakring
